import * as React from 'react';
import { Text, ActivityIndicator, StyleSheet, Image, View  } from 'react-native';

const SingleLaneInfo = () => {



	return(
	null
	)
}

export default SingleLaneInfo

const styles = StyleSheet.create({
  tunnelStyle: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
  },
});